<!-- 伪树，为商品分类页面服务，数据至多三层，由于每个层级样式都需要不一样，所以需要写静态树结构 -->
<template>
	<div class="tree_item">
		<!-- 第一层 品牌 -->
		<div class="first_item" v-for="(item,index) in list">
			<div class="f_title">
				<span class="point" @click.stop="toList(item)">
					{{item.cate_title + ' (' + item.goods_num + ')'}}
				</span>	
			</div>
			<!-- 第二层 分类 -->
			<div v-if="item.children && item.children.length">
				<div class="second_item" v-for="(s_item,s_index) in item.children">
					<div class="second_title">
						<span class="point" @click.stop="toList(s_item)">
							{{s_item.cate_title + ' (' + s_item.goods_num + ')'}}
						</span>
					</div>
					<!-- 第三层 品号 -->
					<div v-if="s_item.children && s_item.children.length" class="third_box">
						<div class="third_item" v-for="(t_item,t_index) in s_item.children">
							<div class="t_item point" @click.stop="toList(t_item)">{{t_item.cate_title + ' (' + t_item.goods_num + ')'}}</div>
						</div>
					</div>
					<!-- <div v-else style="font-size: 14px;margin-top: 8px;color:#1890ff;">暂无商品</div> -->
				</div>
			</div>
			<!-- <div v-else class="second_item">
				<div class="second_title">暂无分类</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	export default {
		name:"Tree",
		components: {},
		props: {
			list: {
				type: Array,
				default: ()=>{
					return []
				}
			},
		},
		data() {
			return {}
		},
		created() {},
		unmounted() {},
		methods: {
			// 携带相应的商品分类id进入“公司商品”页面
			toList(item){
				this.$router.push({
				    path: '/good_list/index',
				    query: {
				        cate_id: item.id
				    }
				})
			},
		}
	}
</script>

<style lang="less" scoped="scoped">
	@import url("../../../assets/less/app.less");
	.point{
		cursor:pointer;
	}
	.tree_item{
		font-size: 16px;
		.first_item{
			padding-top: 22px;
			border-bottom: 1px solid #e8e8e8;
			.f_title{
				text-indent: 9px;
				position: relative;
				padding-bottom: 12px;
				font-weight: bold;
				border-bottom: 1px solid #e8e8e8;
				&::after{
					content:'';
					position: absolute;
					top: 6px;
					left: -1px;
					width: 3px;
					height: 14px;
					background: @primary-color;
				}
			}
			.second_item{
				box-sizing: border-box;
				margin-bottom: 12px;
				margin-top: 20px;
				.second_title{
					font-size: 14px;
					padding-bottom: 8px;
					font-weight: bold;
				}
				.third_box{
					display: flex;
					flex-wrap: wrap;
					.third_item{
						font-size:14px;
						margin-right: 54px;
						color: @primary-color;
						margin-top: 8px;
					}
				}
			}
		}
	}
</style>
