<!-- 商品分类管理 -->
<template>
	<div class="container">
		<div class="btn_group">
			<div class="left">商品分类</div>
			<div class="right">
				<a-button type="primary" @click="add_visible = true">新增分类</a-button>
				<a-button @click="edit_visible = true">编辑分类</a-button>
				<a-button type="danger" @click="del_visible = true">删除分类</a-button>
			</div>
		</div>
		<div class="cate_page">
			<Tree class="tree" :list="list"></Tree>
			<div class="del_list">
				<div class="head">
					<div class="title">{{'已删除 (' + del_list.length + ')'}}</div>
					<div class="right">
						<a-button type="primary" v-if="!recovery_mode" @click="recovery_mode = true">恢复</a-button>
						<a-button v-if="recovery_mode"
							@click="recovery_mode = false;checked_del = [];is_del_all_checked = false">取消</a-button>
						<a-button type="primary" v-if="recovery_mode" @click="recovery_confirm">确定恢复</a-button>
					</div>
				</div><!-- head -->
				<a-checkbox v-model:checked="is_del_all_checked" :indeterminate="indeterminate" @change="del_check_all"
					v-if="recovery_mode">
					全选
				</a-checkbox>
				<div>
					<div class="list" v-if="!recovery_mode">
						<div class="item" v-for="(item,index) in del_list" :key="index">{{item.cate_title}}</div>
					</div>
					<div class="list" v-else>
						<a-checkbox class="item" v-for="(item,index) in del_list" :key="index"
							:checked="checked_del.findIndex(vo=>{return vo.id === item.id}) > -1"
							@click="del_check_change(item)">{{item.cate_title}}</a-checkbox>
					</div>
				</div>
			</div><!-- del_list -->
		</div>

		<a-modal title="新增分类" v-model:visible="add_visible" @ok="add_ok" width="800px" class="modal">
			<div class="item">
				<div class="label">新增分类级别</div>
				<a-select v-model:value="add_form.type" style="width: 300px" placeholder="请选择分类级别" :options="cate_level"
					@change="add_select_change"></a-select>
			</div>
			<div class="item" v-show="add_form.type !== 'brand'">
				<div class="label">请选择上级分类</div>
				<a-tree-select v-model:value="add_form.pid" style="width: 300px" :tree-data="pid_list"
					placeholder="请选择上级分类" :replaceFields="{title:'cate_title',value:'id'}" @change="add_tree_change"
					treeDefaultExpandAll>

				</a-tree-select>
			</div>
			<div class="item">
				<div class="label">新增分类名称</div>
				<a-input style="width: 300px" v-model:value="add_form.name" placeholder="请输入分类名称" />
			</div>
			<div class="item">
				<div class="label">单位</div>
				<div>
					<p class="unit_p" v-for="(item,index) in unit_list">
						<a-input style="width: 300px" v-model:value="item.value" placeholder="请输入单位" />
						<a-icon @click='add_unit(index)' class="unit_color"
							:type="index==unit_list.length-1?'plus-circle':'minus-circle'" />
					</p>
				</div>

			</div>
		</a-modal>

		<a-modal title="编辑分类名称" v-model:visible="edit_visible" @ok="edit_ok" width="800px" class="modal">
			<div class="item">
				<div class="label">请选择待编辑分类</div>
				<a-tree-select v-model:value="edit_form.id" style="width: 300px" :tree-data="list" @change="tree_change"
					placeholder="请选择待编辑分类" :replaceFields="{title:'cate_title',value:'id'}">
				</a-tree-select>
			</div>

			<div class="item">
				<div class="label">编辑分类名称</div>
				<a-input style="width: 300px" v-model:value="edit_form.name" placeholder="请输入分类名称" />
			</div>
			<div class="item">
				<div class="label">编辑单位</div>
				<div>
					<p class="unit_p" v-for="(item,index) in unit_list">
						<a-input style="width: 300px" v-model:value="item.value" placeholder="请输入单位" />
						<a-icon @click='add_unit(index)' class="unit_color"
							:type="index==unit_list.length-1?'plus-circle':'minus-circle'" />
					</p>

				</div>
			</div>
		</a-modal>

		<a-modal title="删除分类" v-model:visible="del_visible" @ok="del_ok" width="800px" class="modal">
			<div class="item">
				<div class="label">请选择要删除的分类</div>
				<a-tree-select v-model:value="del_form.id" style="width: 200px" :tree-data="list"
					placeholder="请选择要删除的分类" :replaceFields="{title:'cate_title',value:'id'}">
				</a-tree-select>
			</div>

			<div class="item">
				<div class="label">请选择继承商品的分类</div>
				<a-tree-select v-model:value="del_form.inherit" style="width: 200px" :tree-data="list"
					placeholder="请选择继承商品的分类" :replaceFields="{title:'cate_title',value:'id'}">
				</a-tree-select>
			</div>

			<div class="del_cate_tips">
				<div>备注说明：</div>
				<div>1.若要删除的分类下无商品，可直接删除，无需选择继承商品的分类</div>
				<div>2.若要删除的分类下有商品，则只支持删除品号类型的分类</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import Tree from './cate/Tree.vue';
	import {
		goodsCate,
		addCate,
		editCate,
		delCate,
		recoveryCate
	} from '@/api/goods.js'
	export default {
		name: 'Cate',
		components: {
			Tree
		},
		data() {
			return {
				unit_list: [{
					value: ''
				}],
				cate_level: [{
						value: 'brand',
						label: '品牌',
					},
					{
						value: 'series',
						label: '系列',
					},
					{
						value: 'number',
						label: '品号',
					},
				], // 分类级别
				add_visible: false, // 新增分类弹窗是否显示
				add_form: {
					pid: null, // 上级分类id
					name: '', // 分类名称
					type: 'brand', // 分类级别 brand品牌 series系列 number品号
				}, // 新增分类需要提交给后端的表单数据
				edit_visible: false, // 编辑分类弹窗是否显示
				edit_form: {
					id: null,
					name: '',
				}, // 编辑分类需要提交给后端的表单数据
				del_visible: false,
				del_form: {
					id: null,
					inherit: null, // 继承商品分类id
				}, // 删除分类需要提交给后端的表单数据
				list: [], // 商品分类列表
				del_list: [], // 已删除的商品分类列表
				is_del_all_checked: false, // 已删除中的分类是否已经全部都被选中了
				recovery_mode: false, // 是否处在恢复模式中
				checked_del: [], // 在恢复模式下已选中的被删除的分类
				pid_list: [], // 新增分类弹窗中的商品分类列表（根据add_form.type处理过的list)
			}
		},
		created() {
			this.get_list();
		},
		watch: {
			// checked_del(newValue, oldValue) {

			// }
		},
		unmounted() {},
		methods: {
			add_unit(index) {
				if (index == this.unit_list.length - 1) {
					// 添加
					this.unit_list.push({
						value: ''
					})
				} else {
					// 删除
					this.unit_list.splice(index, 1)
				}
			},
			tree_change(e) {
				let that = this
				this.unit_list = []
				this.list.forEach(item => {
					if (item.id == e) {
						item.unit.split(',').forEach(it => {
							that.unit_list.push({
								value: it
							})
						})
					} else {
						if (item.children.length > 0) {
							item.children.forEach(it => {
								if (it.id == e && it.unit) {
									it.unit.split(',').forEach(key => {
										that.unit_list.push({
											value: key
										})
									})
								} else {
									if (it.children) {
										it.children.forEach(key => {
											if (key.id == e && key.unit) {
												key.unit.split(',').forEach(key => {
													that.unit_list.push({
														value: key
													})
												})
											} else {
												it.children.forEach(kit => {
													if (kit.id == e && kit.unit) {
														kit.unit.split(',').forEach(key => {
															that.unit_list.push({
																value: key
															})
														})
													}
												})
											}
										})
									} else {
										that.unit_list.push({
											value: ''
										})
									}

								}
							})
						} else {
							that.unit_list.push({
								value: ''
							})
						}
					}


				})

				if (that.unit_list.length == 0) {
					that.unit_list.push({
						value: ''
					})
				}
			},
			// 点击了确定恢复
			recovery_confirm() {
				recoveryCate({
					data: {
						ids: this.checked_del.map(item => {
							return item.id
						}).toString()
					},
					info: true
				}).then(res => {
					this.get_list();
					this.recovery_mode = false;
					this.checked_del = [];
				})
			},
			// 已删除分类中的分类被选中后触发
			del_check_change(item) {
				let index = this.checked_del.findIndex(vo => {
					return vo.id === item.id
				})
				if (index > -1) {
					this.checked_del.splice(index, 1);
				} else {
					this.checked_del.push(item);
				}
				this.is_del_all_checked = this.checked_del.length == this.del_list.length;
			},
			// 已删除分类点击全选按钮
			del_check_all(e) {
				this.checked_del = e.target.checked ? JSON.parse(JSON.stringify(this.del_list)) : [];
			},
			// 删除分类的弹窗点击了确定按钮
			del_ok() {
				if (!this.del_form.id) {
					this.$message.error('请选择要删除的分类');
					return
				}
				delCate({
					data: {
						...this.del_form
					},
					info: true
				}).then(res => {
					this.get_list();
					this.del_visible = false;
					// 重置表单数据
					this.del_form.id = null;
					this.del_form.inherit = null;
				})
			},
			// 编辑分类的弹窗点击了确定按钮
			edit_ok() {
				let unit = []
				this.unit_list.forEach(item => {
					unit.push(item.value)
				})
				editCate({
					data: {
						...this.edit_form,
						unit: unit.join(',')
					},

					info: true
				}).then(res => {
					this.get_list();
					this.edit_visible = false;
					// 重置表单数据
					this.edit_form.id = null;
					this.edit_form.name = '';
					this.unit_list = [{
						value: ''
					}]
				})
			},
			// 新增分类弹窗上级分类树选择器发生改变
			add_tree_change(value, label, extra) {

			},
			// 新增分类的弹窗点击了确定
			add_ok() {
				let unit = []
				this.unit_list.forEach(item => {
					unit.push(item.value)
				})
				addCate({
					data: {
						unit: unit.length > 0 ? unit.join(',') : '',
						...this.add_form
					},
					info: true
				}).then(res => {
					this.get_list();
					this.add_visible = false;
					// 重置新增分类的表单
					this.add_form.name = '';
					this.add_form.pid = null;
					this.unit_list = [{
						value: ''
					}]
				})
			},
			// 新增分类的“分类级别”下拉框发生改变
			add_select_change(event) {
				this.add_form.pid = null;
				this.deal_pid_list();
			},
			// 将商品分类列表根据add_form.type处理成pid_list
			deal_pid_list() {
				let list = JSON.parse(JSON.stringify(this.list));
				this.pid_list = list.map(item => {
					if (this.add_form.type == 'series') {
						item.children = [];
					} else if (this.add_form.type == 'number') {
						item.disabled = true;
						item.children && item.children.map(series_item => {
							series_item.children = [];
						})
					}
					return item;
				});
			},
			// 获取商品分类列表
			get_list() {
				goodsCate().then(res => {
					this.list = res.data.list;
					this.del_list = res.data.delete;
					this.deal_pid_list();
				})
			}
		},
		computed: {
			indeterminate() {
				return !!this.checked_del.length && this.checked_del.length < this.del_list.length
			}
		},
	}
</script>

<style lang="less" scoped="scoped">
	@import 'cate.less';

	.unit_p {
		margin-top: 5px;
	}

	.unit_color {
		color: #0077AA;
		margin-left: 5px;
	}
</style>
